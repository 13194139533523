<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import { getAction, putAction } from '@/command/netTool'
import { refundIsList, orderSourceList } from '@/utils/textFile'

export default {
  name: 'refund',
  data() {
    return {
      ...api.command.getState(),
      refundList: refundIsList,
      refundStatue: 1,
      orderRefundId: null,
      orderItemId: null,
      refundId: null
    }
  },
  mounted() {
    this.getDataInit()
  },
  methods: {
    getDataInit() {
      api.command.getList.call(this, { url: '/order/refund/page', current: 1 })
    },
    getHeader() {
      return [
        {
          name: '单号',
          type: 'input',
          key: 'orderSn',
          placeholder: '请输入单号'
        },
        {
          name: '店铺名称',
          type: 'input',
          key: 'shopName'
        },
        {
          name: '现价',
          type: 'row',
          children: [
            {
              type: 'input',
              cols: 11,
              label: '最低现价',
              key: 'minPrice'
            },
            {
              type: 'text',
              value: '至',
              cols: 2,
              align: 'center'
            },
            {
              type: 'input',
              cols: 11,
              label: '最高现价',
              key: 'maxPrice'
            }
          ]
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'orderSn',
          title: '单号',
          isId: true,
          align: 'left',
          sorter: (a, b) => a.orderSn - b.orderSn
        },
        {
          dataIndex: 'productName',
          title: '出售商品',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div>
                <div class="product-order-item">
                  <img src={records.productPic} />
                  <div>
                    {records.productName.length > 45
                      ? records.productName.substring(0, 45) + '...'
                      : records.productName}
                  </div>
                </div>
              </div>
            )
          }
        },
        {
          dataIndex: 'shopName',
          title: '店铺名称',
          align: 'left'
        },
        {
          dataIndex: 'productCount',
          title: '数量',
          align: 'left'
        },
        {
          dataIndex: 'realAmount',
          title: '实付金额',
          align: 'left',
          customRender: (text, records) => {
            if (records.returnAmount) {
              return (
                <div>
                  <span>{records.realAmount.toFixed(2)}</span>
                  <span>（{records.returnAmount.toFixed(2)} 已退）</span>
                </div>
              )
            } else {
              return (
                <div>
                  <span>{records.realAmount.toFixed(2)}</span>
                </div>
              )
            }
          }
        },

        {
          dataIndex: 'formType',
          title: '订单来源',
          align: 'left',
          customRender: text => (orderSourceList.find(e => e.value == text) || { text: '' }).text,
          // filters: orderSourceList,
          // filterMultiple: false,
          // onExport: text => (orderSourceList.find(e => e.value == text) || { text: '' }).text
        },
        {
          dataIndex: 'status',
          title: '退款情况',
          type: 'badge',
          align: 'center',
          filters: this.refundList,
          render(data) {
            return {
              showDot: true,
              name: {
                1: '等待处理',
                2: '已退款',
                3: '未退款',
                5: '退款失败'
              }[data],
              color: data == 1 || data == 5 ? 'red' : data == 3 ? 'orange' : '#eee'
            }
          },
          onExport: records => {
            return {
              1: '等待处理',
              2: '已退款',
              3: '未退款',
              5: '退款失败'
            }[records]
          },
          filterMultiple: false
        },

        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: '退款处理',
                display: records.status == 1 && records.formType == 1,
                onClick: () => {
                  this.onRowSelect(records, '退款处理')
                }
              },
              {
                name: '主动退款',
                display: records.status == 3 && records.formType == 1,
                onClick: () => {
                  this.onRowSelect(records, '主动退款')
                }
              }
            ].filter(e => e.display)
          }
        }
      ]
    },
    // 批量关闭
    onBatchDelete(keys) {
      return api.order.batchClose.call(this, {
        url: '/common/order/closeBatch',
        params: { idList: keys.map(e => e.id) }
      })
    },
    getButton() {
      return [
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量关闭',
              onClick: this.onBatchDelete
            }
          ]
        }
      ]
    },
    getTableColumns(data) {
      const columns = [].concat(data.orderItemList)
      const arr = [
        {
          name: `V${data.memberLevel}优惠`,
          value: '-￥' + data.memberDiscountAmount,
          display: data.memberDiscountAmount
        },
        {
          name: '运费',
          value: '￥' + data.freightAmount,
          display: data.freightAmount
        },
        {
          name: '运费',
          value: '包邮',
          display: !data.freightAmount
        },
        {
          name: '优惠券',
          //   name: `优惠券${data.couponName}`,
          value: '-￥' + data.couponAmount,
          display: data.couponAmount
        },
        {
          name: '余额券',
          value: '￥' + data.balanceAmount,
          display: data.balanceAmount
        },
        {
          name: '实付金额',
          value: '￥' + data.payAmount,
          display: data.payAmount
        }
      ].filter(e => e.display)
      arr.forEach(e => {
        columns.push({
          productQuantity: e.name,
          totalPrice: e.value
        })
      })
      return columns
    },
    getDrawerForm(data) {
      let _this = this
      let refund = data.refundList.find(e => e.id == this.refundId)

      const form = [
        {
          display: true,
          class: 'order-table',
          form: [
            {
              type: 'table',
              wrapperCol: 24,
              dataSource: [],
              dataSource: this.getTableColumns(data),
              showPagination: false,
              columns: [
                {
                  title: '商品',
                  type: 'lt-200',
                  display: true,
                  customRender: (text, records, index, h) => {
                    if (!records.productPic || !records.productName) return null
                    return (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start'
                        }}
                      >
                        <img
                          src={records.productPic}
                          style={{
                            width: '30px',
                            height: '30px',
                            marginRight: '10px'
                          }}
                        />
                        <div
                          style={{
                            maxWidth: '260px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {/* {records.productName.length > 10
                      ? records.productName.substring(0, 10) + '……'
                      : records.productName} */}
                          {records.productName}
                          <br />
                          {refund.productAttr ? records.productAttr : ''}
                        </div>
                      </div>
                    )
                  }
                },
                // {
                //   title: '规格',
                //   dataIndex: 'productAttr',
                //   type: 'ct-100',
                //   display: refund.productAttr
                // },
                {
                  title: '数量',
                  dataIndex: 'productQuantity',
                  type: 'ct-100',
                  display: true
                },
                {
                  title: '总价',
                  display: true,
                  customRender: (text, records, index, h) => {
                    if (records.totalPrice) return records.totalPrice
                    return <div>￥ {records.productQuantity * records.productPrice}</div>
                  }
                },
                {
                  title: '已退款',
                  dataIndex: 'refundAmount',
                  display: true,
                  customRender: (text, records, index, h) => {
                    console.log(records)
                    return <div> {text ? '￥' + (records.refundAmount ?? 0) : ''}</div>
                  }
                }
              ].filter(e => e.display)
            }
          ]
        },
        {
          display: true,
          title: '配送',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '配送方式',
                  value: { '0': '快递配送', '1': '自行配送' }[data.deliveryType] || '',
                  display: true
                },
                {
                  name: '收件人',
                  value: data.receiveMan,
                  display: data.receiveMan
                },
                {
                  name: '快递单号',
                  value: data.deliverySn,
                  display: data.deliverySn
                },
                {
                  name: '收货人电话',
                  value: data.receiverPhone,
                  display: data.receiverPhone
                },
                {
                  name: '收货地址',
                  width: '100%',
                  display: data.receiverProvince,
                  value:
                    (data.receiverProvince || '') +
                    ' ' +
                    (data.receiverCity || '') +
                    ' ' +
                    (data.receiverRegion || '') +
                    ' ' +
                    (data.receiverDetailAddress || '')
                },
                {
                  name: '订单备注',
                  value: data.remark,
                  display: data.remark
                }
              ].filter(e => e.display)
            }
          ]
        },
        ..._this.coupons(data),
        {
          display: true,
          title: '基础',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '单号',
                  value: data.orderSn,
                  display: data.orderSn
                },
                {
                  name: '店铺名称',
                  value: data.shopName,
                  display: data.shopName
                },
                {
                  name: '购买人',
                  value: data.buyName,
                  display: data.buyName
                },
                {
                  name: '订单状态',
                  value: ['待付款', '待使用', '待发货', '待签收', '待评价', '已完成', '已关闭'][Number(data.status)],
                  display: data.status
                }
              ].filter(e => e.display)
            }
          ]
        },
        {
          display: true,
          title: '退款详情',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '货物状态',
                  value: ['已收到货', '未收到货'][Number(refund.receiveStatus) - 1],
                  display: refund.receiveStatus
                },
                {
                  name: '退款金额',
                  value: refund.returnAmount,
                  display: refund.returnAmount
                },
                {
                  name: '退款原因',
                  width: '100%',
                  value: refund.reason,
                  display: refund.reason
                },
                {
                  name: '反馈问题',
                  width: '100%',
                  value: refund.description,
                  display: refund.description
                }
              ].filter(e => e.display)
            },
            {
              type: 'imageGroup',
              value: refund.proofPics
            }
          ]
        },
        ..._this.refundListData(data),
        {
          display: true,
          title: '物流信息',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '配送方式',
                  value: { '0': '快递配送', '1': '自行配送' }[data.deliveryType] || '',
                  display: true
                },
                {
                  name: '快递公司',
                  value: data.deliveryCompany,
                  display: data.deliveryType == '0'
                },
                {
                  name: '快递单号',
                  value: data.deliverySn,
                  display: data.deliveryType == '0'
                }
              ].filter(e => e.display)
            },
            {
              type: 'timeLine',
              typeData: (data.logisticsList || []).map(e => {
                return {
                  name: e.acceptStation,
                  value: e.acceptTime
                }
              })
            }
          ]
        },
        {
          display: true,
          title: '记录',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '支付时间',
                  value: data.paymentTime,
                  display: data.paymentTime
                },
                {
                  name: '发货时间',
                  value: data.deliveryTime,
                  display: data.deliveryTime
                },
                {
                  name: '完成时间',
                  value: data.updateTime,
                  display: data.updateTime
                },
                {
                  name: '退款时间',
                  value: data.refundTime,
                  display: data.refundTime
                }
              ].filter(e => e.display)
            }
          ]
        }
      ].filter(e => e.display)

      return form
    },
    coupons(data) {
      return (data.userCodeVOList || []).map((e, i) => {
        return {
          title: '券' + (i + 1),
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '券状态',
                  value: ['未使用', '已使用', '已退款'][e.status],
                  display: true
                },
                {
                  name: '时间',
                  value: e.usedTime,
                  display: e.usedTime
                },
                {
                  name: '退款原因',
                  value: e.reason,
                  width: '100%',
                  display: e.reason
                },
                {
                  name: '更多原因',
                  value: e.description,
                  width: '100%',
                  display: e.description
                }
              ].filter(e => e.display)
            }
          ]
        }
      })
    },
    refundListData(data) {
      //   console.log(data.refundList, 'refundList')
      if (this.refundStatue != 1) {
        return (data.refundList || []).map((e, i) => {
          return {
            title: '售后处理',
            form: [
              {
                type: 'textGroup',
                value: [
                  {
                    name: '问题类型',
                    value: ['仅退款', '退货退款'][Number(e.returnType)]
                  },
                  {
                    name: '退款金额',
                    value: e.returnAmount,
                    props: {
                      addonBefore: '￥'
                    }
                  },
                  {
                    name: '问题描述',
                    width: '100%',
                    type: 'textArea',
                    value: e.handleNote
                  }
                ]
              }
            ]
          }
        })
      } else {
        return []
      }
    },
    getRefundDrawerForm(data, clickState, formData) {
      let refund = data.refundList.find(e => e.id == this.refundId)
      // let refund = data.refundList.find(e => e.status == 1 || e.status == 3) || {}
      formData.returnCount = formData.returnCount ? formData.returnCount : refund.productCount ? refund.productCount : 1

      formData.returnAmount = formData.returnAmount
        ? formData.returnAmount
        : refund.productCount * refund.productRealPrice

      const form = [
        {
          display: true,
          title: '购买方信息',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '收货人',
                  value: data.buyName
                },
                {
                  name: '收货人电话',
                  value: data.buyPhone
                },
                {
                  name: '实付金额',
                  value: data.payAmount
                }
              ]
            }
          ]
        },
        {
          display: clickState == '退款处理',
          title: '退款详情',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '货物状态',
                  value: ['已收到货', '未收到货'][Number(refund.receiveStatus) - 1]
                },
                {
                  name: '退款金额',
                  value: refund.returnAmount
                },
                {
                  name: '退款原因',
                  width: '100%',
                  value: refund.reason
                },
                {
                  name: '反馈问题',
                  width: '100%',
                  value: refund.description
                }
              ]
            },
            {
              type: 'imageGroup',
              value: refund.proofPics
            }
          ]
        },
        {
          display: true,
          title: '售后处理信息',
          form: [
            {
              name: '处理结果',
              key: 'handleResult',
              type: 'radioButton',
              display: true,
              defaultValue: '0',
              labelAlign: 'left',
              typeData: [
                {
                  name: '同意退款',
                  value: '0'
                },
                {
                  name: '退款退货',
                  value: '1'
                },
                {
                  name: '供应商补发',
                  value: '2'
                },
                {
                  name: '已经处理',
                  value: '3'
                },
                {
                  name: '驳回退款',
                  value: '4'
                }
              ]
            },
            {
              type: 'inputNumber',
              name: '商品数量',
              key: 'returnCount',
              labelAlign: 'left',
              display: formData.handleResult == 0 || formData.handleResult == 1,
              props: {
                min: 1,
                max: refund.productCount
              }
            },
            {
              type: 'inputNumber',
              name: '退款金额',
              key: 'returnAmount',
              labelAlign: 'left',
              props: {
                min: 0
                // max: formData.returnAmount
              },
              display: formData.handleResult == 0 || formData.handleResult == 1
            },
            // {
            //   type: 'input',
            //   name: '退款金额',
            //   key: 'returnAmount',
            //   props: {
            //     addonBefore: '￥'
            //   },
            //   display: formData.handleResult == 0 || formData.handleResult == 1
            // },
            {
              display: true,
              name: '问题描述',
              type: 'textArea',
              labelAlign: 'left',
              key: 'handleNote'
            }
          ].filter(e => e.display)
        }
      ].filter(e => e.display)
      return form
    },
    onRowSelect(records, clickState = null) {
      this.refundStatue = records.status
      switch (records.formType) {
        case '1':
          this.getDataOrderRefund(records, 'product', clickState)
          break
        case '2':
          this.getDataOrderRefund(records, 'agr', clickState)
          break
        case '3':
          this.getDataOrderRefund(records, 'home', clickState)
          break
        default:
          this.getDataOrderRefund(records, 'farming', clickState)
          break
      }
    },
    getDataOrderRefund(records, str, clickState = null) {
      this.orderRefundId = records.orderId
      this.orderItemId = records.orderItemId
      this.refundId = records.id
      getAction(`/api/${str}/order/detail?id=${records.orderId}`).then(result => {
        if (result.code == 200) {
          let resultData = JSON.parse(JSON.stringify(result.data))
          let refundData = resultData.refundList.find(e => e.id == this.refundId)

          if (clickState != '退款处理' && clickState != '主动退款') {
            const data = this.getDrawerForm(resultData)
            apiTool.showDrawer({
              title: '订单信息',
              width: '750px',
              view: DrawerForm,
              viewProps: { data }
            })
          } else {
            apiTool.showDrawer({
              title: '订单信息',
              width: '750px',
              view: DrawerForm,
              viewProps: {
                formProps: {
                  layout: 'horizontal',
                  labelCol: { span: 3 },
                  wrapperCol: { span: 21 }
                },
                form: refundData,
                data: formData => this.getRefundDrawerForm(resultData, clickState, formData)
              },
              success: data => {
                this.handleRefundComfig(data, resultData, str)
              },
              foot: ({ close, submit }) => {
                return [
                  {
                    name: '取消',
                    onClick: close
                  },
                  {
                    name: '确认处理',
                    type: 'primary',
                    onClick: submit
                  }
                ]
              }
            })
          }
        } else {
          this.$message.error(result.msg)
        }
      })
    },
    handleRefundComfig(dataSource, result, str) {
      const { data, setHidden } = dataSource
      let params = {
        handleResult: data.handleResult,
        handleNote: data.handleNote,
        returnCount: data.returnCount,
        returnAmount: Number(data.returnAmount),
        itemId: this.orderItemId,
        refundId: this.refundId
      }
      putAction('/order/refund', {
        ...params
        // ...data,
        // returnAmount: Number(data.returnAmount),
        // orderId: this.orderRefundId,
        // itemId: this.orderItemId,
        // refundId: this.refundId
      }).then(result => {
        if (result.code == 200) {
          if (result.data != 5) {
            this.$message.success('操作成功')
          } else {
            this.$message.error('操作失败')
          }
          setHidden()
          this.getDataInit()
        } else {
          this.$message.error(result.msg)
        }
      })
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
        onSelectRow={this.onRowSelect}
      />
    )
  }
}
</script>

<style lang="less">
.product-order-item {
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 5px;
    object-fit: cover;
  }
}
.product-number {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  div {
    height: 30px;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}
</style>
